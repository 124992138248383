.progress-bar-container {
    width: 100%;
    margin: 18px 0 6px 0;
    position: relative;
  }
  
  .progress-bar {
    position: relative;
    width: 100%;
    height: 6px;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: visible;
  }
  
  .progress {
    height: 100%;
    background: transparent linear-gradient(90deg, #8250B3 0%, #718FE5 100%) 0% 0% no-repeat padding-box;
    position: relative;
    z-index: 1;
  }
  
  .progress-dot {
    position: absolute;
    top: 50%;
    left: 0; /* Adjusted to align with progress */
    width: 14px;
    height: 14px;
    background-color: #fff;
    border-radius: 50%;
    transform: translate(-50%, -50%); /* Adjusted to center horizontally */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px 0px, rgba(0, 0, 0, 0.25) 0px 1px 3px 0px;
  }
  
  .progress-label {
    position: absolute;
    top: -30px;
    left: 0; /* Adjusted to align with progress */
    background-color: #e0e0e0;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 12px;
    white-space: nowrap;
    transform: translateX(0%); /* Adjusted to center horizontally */
    color: #727270;
  }
  
.mdl-demo .dropdown-trigger > span:after {
  font-size: 12px;
  color: #555;
}

.mdl-demo .toggle {
  font: normal normal normal 22px/1 "Material Icons";
  color: #555;
  white-space: pre;
  margin-right: 4px;
}

.mdl-demo .toggle.collapsed::after {
  cursor: pointer;
  content: "\E5CF";
  vertical-align: sub;
}

.mdl-demo .toggle.expanded::after {
  cursor: pointer;
  content: "\E5CE";
  vertical-align: sub;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
  font: normal normal normal 22px/1 "Material Icons" !important;
  content: "\E5CF" !important;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after {
  font: normal normal normal 22px/1 "Material Icons" !important;
  content: "\E5CE" !important;
}
  
.react-dropdown-tree-select .dropdown .dropdown-trigger {
  /* height: 36px; */
  border-radius: 5px;
  width: 100% !important;
  max-height: 100% !important;
  border: 1px solid #e5e5e5 !important;
  padding: 0 10px 0px 10px;
  display: flex !important;
  align-items:center;
  line-height: 18px !important;
  padding: 3px 4px !important;
}

.toggle {
  margin-right: 8px !important;
  margin-left: 8px !important;
  /* width: 10px; */
  display: inline-block;
  text-align: center;
  font-weight: 800;
  font-size: 20px;
}

.tag-item .search {
  border: none;
  border-bottom: 0px solid #ccc !important;
  outline: none;
  width: 100%;
}

.tag-list {
  display: inline;
  padding: 0;
  margin: 0;
  width: 100%;
}

.react-dropdown-tree-select .dropdown .dropdown-content {
  position: absolute;
  padding: 4px;
  z-index: 5 !important;
  background: #fff;
  border-top: 1px solid rgba(0,0,0,.05);
  box-shadow: 0 5px 8px rgba(0,0,0,.15);
  width: auto !important;
  min-width: 100% !important;
  max-height: 350px;
  overflow-y: scroll;
}

.tag-item{
  display: none !important;
  width: 100%;
}
.tag-item:last-child {
  display: inline-block !important;
  width: 100%;
}

.react-dropdown-tree-select .dropdown {
  position: relative;
  display: table;
  width: 100% !important;
}
.checkbox-item, .radio-item {
  vertical-align: inherit !important;
}

/* component styles */
.hide:not(.match-in-children) {
  display: none;
}
.react-dropdown-tree-select .dropdown {
    position: relative;
    display: table;
  }
.react-dropdown-tree-select .dropdown .dropdown-trigger {
      padding: 4px;
      line-height: 20px;
      max-height: 200px;
      display: inline-block;
      overflow: auto;
      border: 1px solid rgb(185, 185, 185);
    }
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow {
        cursor: pointer;
      }
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom::after {
          content: '\25BC';
          vertical-align: middle;
          color: #3c3c3c;
          margin-right: 2px;
        }
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top::after {
          content: '\25B2';
          vertical-align: middle;
          color: #3c3c3c;
          margin-right: 2px;
        }
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.disabled {
          cursor: not-allowed;
        }
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.disabled.bottom::after {
            color: rgb(185, 185, 185);
          }
.react-dropdown-tree-select .dropdown .dropdown-content {
      position: absolute;
      padding: 4px;
      z-index: 1;
      background: white;
      border-top: rgba(0, 0, 0, .05) 1px solid;
      -webkit-box-shadow: 0 5px 8px rgba(0, 0, 0, .15);
              box-shadow: 0 5px 8px rgba(0, 0, 0, .15);
    }
.react-dropdown-tree-select .dropdown .dropdown-content .search {
        width: 100%;
        border: none;
        border-bottom: solid 1px #ccc;
        outline: none;
      }
.react-dropdown-tree-select .dropdown .dropdown-content ul {
        margin: 0;
        padding: 0;
      }

.react-dropdown-tree-select .dropdown .dropdown-trigger {
  line-height: 19px !important;
  max-height: 50px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  overflow: auto;
  border: 1px solid #DCDCDC !important;
  padding: 7px 14px !important;
  border-radius: 12px !important;
  width: 100% !important;
  background-color: #fff !important;
  
}

.tag-item .search {
  border: none;
  border-bottom: 0px solid #ccc !important;
  outline: none;
}

.react-dropdown-tree-select .dropdown {
  position: relative;
  display: block !important;
}
.react-dropdown-tree-select .dropdown .dropdown-content {
    position: absolute;
    z-index: 1;
    background: #fff;
    border-top: 1px solid rgba(0, 0, 0, .05);
    box-shadow: 0 5px 8px rgba(0, 0, 0, .15);
    border: 1px solid #DCDCDC !important;
    padding: 2px 14px !important;
    border-radius: 12px !important;
    width: 100%;
}

.toggle {
  white-space: pre;
  margin-right:10px !important;
  cursor: pointer;
  font-style: normal !important;
  font-size: 24px;
}

.node {
  list-style: none;
  white-space: nowrap;
  padding: 4px;
  display: flex !important;
  align-items: center !important;
  background-color: #ffffff !important;
}

.dropdown-heading-value {
    font-size: 1rem;
}

.item-renderer {
    font-size: 1rem;
}

.rmsc .dropdown-container:focus-within {
    box-shadow: var(--rmsc-main) 0 0 0 0px !important;
    border-color: var(--rmsc-main);
}
.top-bar {
    padding: 0px;
    color: #fff;
    background-color: #e3e3e3;
    border-radius: 14px 14px 0 0;
    padding: 0px;
    color: #fff;
    background-color: #e3e3e3;
    border-radius: 14px 14px 0 0;
    position: sticky;
    top: 82px;
    z-index: 999;
}

.bg-active {
    background-color: #007EFF;
}



.top-bar ul {
    list-style-type: none;
    text-align: left;
    padding-left: 0rem;
    margin-bottom: 0rem;
    height: 44px;
}

.top-bar ul li {
    display: inline-block;
    margin-right:3px;
    padding: 10px 20px !important;
}

.top-bar ul li.active {
    background-color: #578FFF !important;
}
.top-bar ul li.active:last-child {
    border-radius: 0px 0px 0 0px !important;
}
.top-bar ul li.active:first-child {
    border-radius: 14px 0 0 0px !important;
}
.top-bar ul div li.active:first-child {
  border-radius: 0px 0 0 0px !important;
}

.topbar-d-flex{
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}
.top-bar-d-flex-box{
  display: flex;
  max-width: 86%;
  /* min-width: 85%; */
}

.topbar-d-flex-link{
  display: flex !important;
  align-items: center;
}

.top-bar ul li a {
    text-decoration: none;
    padding: 5px 10px;
    color: #737373 !important;
    transition: all 0.3s ease;
    font-size: 16px !important;
    font-weight: 500 !important;
}

.top-bar ul li.active a {
    text-decoration: none;
    padding: 5px 10px;
    color: #fff !important;
    transition: all 0.3s ease;
}

.cross-btn-closs{
    background-color: #fff;
    padding: 4px 6px;
    color: #588fff;
    border-radius: 20px;
    font-size: 12px;
    cursor: pointer;
}


.CNDashboardSection {

    .div-center-ui{
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height:80vh;
    }

      .table-responsive{
    border-radius: 14px;
    }
    
      .react-datepicker-wrapper {
      display: inline-block;
      padding: 0;
      border: 0;
      width: 100%;
    }
    
    
      .modal-backdrop {
      z-index: 1040 !important;
      background-color: #9B9B9B !important;
    }
    
      .modal-dialog {
      z-index: 1050 !important;
    }
    
      .modal-header {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      padding: var(--bs-modal-header-padding);
      border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
      border-top-left-radius: var(--bs-modal-inner-border-radius);
      border-top-right-radius: var(--bs-modal-inner-border-radius);
      flex-direction: column-reverse;
      flex-wrap: wrap;
    }
    
      .modal-header {
      border: 0;
    }
    
      .modal-title{
        font-size: 20px;
        color: #404040;
        font-weight: 600;
        line-height: 1.2;
    }
    
      .modal-subtitle{
      font-size: 18px;
      color: #404040;
      font-weight: 400;
    }
    
      .modal-content {
      border-radius: 24px !important;
    }
      .modal-header{
      padding-bottom: 0;
    }
    
    
      .modal-header {
      display: flex;
      flex-shrink: 0;
      flex-direction: row !important;
      align-items: flex-start;
      align-content: center;
    }



    
      .uplaod-file-btn{
        
    color: #fff!important;
    background: #7B7B7B !important;
    border: 1px solid #7B7B7B!important;
    font-size: 16px !important;
    padding: 12px 30px !important;
    border-radius: 10px !important;
    line-height: 1.2 !important;
    font-weight: 500 !important;
  }

    .uplaod-file-btn:hover {
    color: #fff !important;
    background: #1279be !important;
    border: 1px solid #1279be !important;
  }

    label.col-form-label {
    font-weight: 600;
  }

    .clear-btn{
      
    color: #fff!important;
    background: #808080 !important;
    border: 1px solid #808080!important;
    font-size: 16px !important;
    padding: 12px 50px !important;
    border-radius: 10px !important;
    line-height: 1.2 !important;
    font-weight: 500 !important;
  }

    .clear-btn:hover {
    color: #fff !important;
    background: #1279be !important;
    border: 1px solid #1279be !important;
  }



    .search-btn{
      
    color: #fff!important;
    background: #12BE46 !important;
    border: 1px solid #12BE46!important;
    font-size: 16px !important;
    padding: 12px 24px !important;
    border-radius: 10px !important;
    line-height: 1.2 !important;
    font-weight: 500 !important;
  }

    .search-btn:hover {
    color: #fff !important;
    background: #1279be !important;
    border: 1px solid #1279be !important;
  }


    .clear-search-btn{
      
    color: #fff!important;
    background: #808080 !important;
    border: 1px solid #808080!important;
    font-size: 16px !important;
    padding: 12px 24px !important;
    border-radius: 10px !important;
    line-height: 1.2 !important;
    font-weight: 500 !important;
  }

    .clear-search-btn:hover {
    color: #fff !important;
    background: #1279be !important;
    border: 1px solid #1279be !important;
  }

    .input-icon-seacrh {
    position: absolute;
    top: 10px;
    left: 10px;
    height: 24px;
    font-size: 24px;
    line-height: 28px;
    text-align: left;
    color: #ffeba7;
    transition: all 200ms linear;
  }

    .react-tabs__tab--selected {
    background: #578FFF!important;
    border-color: #fff !important;
    color: #fff !important;
    border-radius: 0 !important;
  }

    .react-tabs__tab-list {
    border-bottom: 0px solid #aaa;
    margin: 0 0 10px;
    padding: 0;
  }

    .react-tabs__tab {
    background: #E3E3E3 !important;
    border-color: #fff !important;
    color: #737373 !important;
    border-radius: 0 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    padding: 10px 34px !important;
    margin: 0 0px 0px 0 !important;
    text-transform: uppercase !important;;
    cursor: pointer;
    transition: all 0.3s ease;
    border-top: 0 !important;
    bottom: 0px !important;
  }

    .react-tabs__tab:hover {
    background: #578FFF !important;
    border-color: #fff !important;
    color: #fff !important;
  }

    .react-tabs__tab--selected {
    background: #578FFF !important;
    border-color: #fff !important;
    color: #fff !important;
    border-radius: 0 !important;
  }

    .react-tabs__tab-list {
    border-bottom: 0px solid #aaa;
    margin: 0 0 10px;
    padding: 0;
    background-color: #e3e3e3;
    border-radius: 14px 14px 0 0;
  }

    .react-tabs__tab-panel {
    display: none;
    padding: 10px;
    border-radius: 10px;
    background-color: #fff;
  }

    .react-tabs__tab-panel--selected {
    display: block;
  }

    .react-tabs__tab-panel--selected .content-configuration{
    padding: 10px 20px;
  }

    .react-tabs__tab-list {
    border-bottom: 0px solid #aaa !important;
    margin: 0 0 10px;
    padding: 0;
  }

    .react-tabs__tab-list {
    border-bottom: 1px solid #aaa;
    margin: 0 0 0px !important; 
    padding: 0;
  }

    .react-tabs__tab:first-child {
  border-radius: 14px 0 0 0px !important;
  }

    .react-tabs__tab:last-child {
  border-radius: 0 0px 0px 0 !important;
  }

    .react-tabs__tab-panel {
    display: none;
    padding: 20px;
    border-radius: 0px 14px 14px 14px !important;
    /* background-color: #F9F9F9; */
    height: 80vh;
    width: 100%;
    overflow-x: hidden;
  }




    .call-now-btn{
    color: #fff!important;
    background: #12BE46 !important;
    border: 1px solid #12BE46!important;
    font-size: 12px !important;
    padding: 2px 6px !important;
    border-radius: 20px !important;
    line-height: 1.2 !important;
    font-weight: 500 !important;
  }

    .call-now-btn:hover {
    color: #fff !important;
    background: #1279be !important;
    border: 1px solid #1279be !important;
  }


    .call-now-disable-btn{
    color: #fff!important;
    background: #E2E2E2 !important;
    border: 1px solid #E2E2E2!important;
    font-size: 12px !important;
    padding: 2px 6px !important;
    border-radius: 20px !important;
    line-height: 1.2 !important;
    font-weight: 500 !important;
  }

  .coach-call-btn{
    color: #fff!important;
    background: transparent !important;
    border: 1px solid transparent!important;
    font-size: 32px !important;
    padding: 4px 6px !important;
    border-radius: 20px !important;
    line-height: 1.2 !important;
    font-weight: 500 !important;
  }

  .coach-call-btn:hover {
    color: #fff !important;
    background: #12BE46 !important;
    border: 1px solid #12BE46 !important;
  }

  .coach-call-close-btn:hover {
    color: #fff !important;
    background: #808080 !important;
    border: 1px solid #808080 !important;
  }



  .add-new-member-btn{
    color: #fff!important;
    background: #03335b!important;
    border: 1px solid #03335b!important;
    font-size: 12px !important;
    padding: 3px 10px !important;
    border-radius: 40px !important;
    line-height: 1.25 !important;
    font-weight: 600 !important;
  }

  .add-new-member-btn:hover {
    color: #fff !important;
    background: #1279be !important;
    border: 1px solid #1279be !important;
  }



  .cancel-btn{
    color: #fff!important;
    background: #808080 !important;
    border: 1px solid #808080!important;
    font-size: 16px !important;
    padding: 12px 30px !important;
    border-radius: 10px !important;
    line-height: 1.2 !important;
    font-weight: 500 !important;
  }

  .cancel-btn:hover {
    color: #fff !important;
    background: #1279be !important;
    border: 1px solid #1279be !important;
  }

  .btn-info{
    font-size: 14px !important;
    padding: 6px 10px !important;
    border-radius: 8px !important;
    line-height: 1.2 !important;
    font-weight: 600 !important;
  }

  .btn-info:hover {
    color: #fff !important;
    background: #578FFF !important;
    border: 1px solid #578FFF!important;
  }
  .book-now-btn{
    color: #fff!important;
    background: #3552b5 !important;
    border: 1px solid #3552b5 !important;
    font-size: 14px !important;
    padding: 6px 10px !important;
    border-radius: 8px !important;
    line-height: 1.2 !important;
    font-weight: 600 !important;
  }

  .book-now-btn:hover {
    color: #fff !important;
    background: #578FFF !important;
    border: 1px solid #578FFF!important;
  }



  .program-save-btn{
      
    color: #fff!important;
    background: #12BE46 !important;
    border: 1px solid #12BE46!important;
    font-size: 16px !important;
    padding: 12px 50px !important;
    border-radius: 10px !important;
    line-height: 1.2 !important;
    font-weight: 500 !important;
  }

  .program-save-btn:hover {
    color: #fff !important;
    background: #1279be !important;
    border: 1px solid #1279be !important;
  }

}


.icon-call{
  height: 26px;
}

.coach-card-title {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: .2rem;
  text-align: left;
}

.coach-card-subtitle {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
  text-align: left;
}

/* 
.coach-calling-btn{
  color: #fff!important;
  background: #32C100!important;
  border: 1px solid #32C100!important;
  font-size: 32px !important;
  padding: 7px 10px !important;
  border-radius: 40px !important;
  line-height: 1.1 !important;
  font-weight: 500 !important;
}

.coach-calling-btn:hover {
  color: #fff !important;
  background: #808080 !important;
  border: 1px solid #808080 !important;
} */


.coach-other-btn{
  color: #fff!important;
  background: transparent!important;
  border: 1px solid transparent!important;
  font-size: 32px !important;
  padding: 7px 10px !important;
  border-radius: 40px !important;
  line-height: 1.1 !important;
  font-weight: 500 !important;
}

.coach-other-btn:hover {
  color: #fff !important;
  background: #808080 !important;
  border: 1px solid #808080 !important;
}


.top-bar .dropdown-menu{
  border-radius: 10px;
  border: 1px solid #d1d1d1;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  height: auto !important;
  max-height: 478px !important;
  overflow-y: scroll;
  max-width: 320px;
  background-color: #f1f5fd;
  padding: 0;
}

.top-bar .dropdown-menu .notification-item-border {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px !important;
}

.top-bar .dropdown-menu .notification-item-border a{
  width: 86%;
  padding: 5px 10px;
}
.ContentMemberEngagement{
    padding: 10px 20px;
}

.member-engagement{
    background-color: #fff;
    color: #000;
    padding: 10px;
    border-radius: 14px;
    /* height: 87vh; */
    display: block;
    width: 100%;
    /* overflow-y: scroll;
    overflow-x: hidden; */
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    /* margin-bottom: 4rem !important; */
}

.member-engagement::-webkit-scrollbar{
    display: none;
}

.ContentMemberEngagement .main-title {
    font-size: 22px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 10px;
}

  .table-bg{
    background: #F1F1F2 0% 0% no-repeat padding-box;
    border-radius: 12px;
  }

.table-bg .table>:not(caption)>*>* {
    background-color: #F1F1F2 !important;
    color: #636773;
}

.table-bg thead {
    border-bottom: 1px solid #707070;
}

.table-icon{
    height: 20px;
    width: 20px;;
}

.member-engagement .bg-grey {
  background-color: #f0f4ff;
}

.member-engagement .bg-lightgreen {
  background-color: #e6faec;
}

.member-engagement .bg-lightblue {
  background-color: #f0f4ff;
}

.member-engagement .bg-lightred {
  background-color: #ffeef0;
}

.member-engagement .card-icon {
  width: 54px;
  height: 54px;
  margin-bottom: 1rem;
}

.member-engagement .card-title {
  font-size: 30px; 
  font-weight: 600; 
  color: #333; 
  margin-bottom: 0.3rem; 
  text-align: left;
}

.member-engagement .card-text {
  font-size: 14px;
  color: #636773;
  margin-top: 0rem;
  font-weight: 400;
  text-align: left;
}


.sticky-top-bar{
  position: sticky !important;
  top: 56px;
  background-color: #f1f5fc;
  z-index: 2;
  /* padding-bottom: 10px; */
}

.In-progress {
    color: #14AC28;
}

.Completed {
    color: #0138EE;
}

.Paused {
    color: #DB0202;
}

.card-box-member{
  border-radius: 14px;
  border: 0;
  height: 110px;
}

.card-box-member-dflex{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.member-card-title {
  font-size: 22px;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.3rem;
}

.member-card-title span {
  font-size: 14px;
  font-weight: 400;
  color: #636773;
}

.member-card-text {
  font-size: 14px;
  color: #636773;
  margin-top: 0rem;
  font-weight: 400;
  text-align: center;
}

.member-card-title1 {
  font-size: 18px;
  font-weight: 600;
  color: #676767;
  margin-bottom: 0.3rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.member-card-text1 {
  font-size: 12px;
  color: #636773;
  margin-top: 0rem;
  font-weight: 500;
  text-align: center;
  border-top: 1px solid #AEAEAE;
  padding-top: 8px;
}

.success-card-text{
  color:#70DA40;
  font-size: 14px;
  font-weight: 400;
}

.failed-card-text{
  color:#DE0007;
  font-size: 14px;
  font-weight: 400;
}

.StatusOngoing{
  color:#636773;
  font-size: 14px;
  font-weight: 600;
}

.StatusOngoing span{
  color:#636773;
  font-size: 14px;
  font-weight: 400;
  padding-left: 10px;
}
.dot-ongoing{
  color: #70DA40;
  font-size: 20px;
  vertical-align: sub;
}

.coach-call-box{
  border: 0;
  background: #4A4A4A 0% 0% no-repeat padding-box;
  border-radius: 24px;
  padding: 10px;
}

.coach-call-box .coach-pics-box {
  height: 120px;
  position: relative;
  width: 120px;
}

.coach-call-box .coach-pics-img {
  background-color: #fff;
  border: 1px solid #e8edf7;
  border-radius: 230px;
  display: block;
  height: 120px;
  object-fit: cover;
  width: 120px;
}

.coach-call-box .coach-card-title {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0.3rem;
  text-align: center;
}

.coach-call-box .coach-card-subtitle {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 0rem;
  text-align: center;
}
.coach-call-box .coach-call-btn-box{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.coach-call-box-1 .user-pics-box {
  height: 72px;
  position: relative;
  width: 72px;
}

.coach-call-box-1 .user-pics-img {
  background-color: #fff;
  border: 1px solid #e8edf7;
  border-radius: 230px;
  display: block;
  height: 72px;
  object-fit: cover;
  width: 72px;
}

.coach-call-box-1 .user-card-title {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin-bottom: 0rem;
  text-align: left;
}
.coach-call-box-1 .user-card-subtitle {
  font-size: 16px;
  font-weight: 500;
  color: #636773;
  margin-bottom: 0rem;
  line-height: 1.7;
  text-align: left;
}
.coach-call-box-1 .user-card-text {
  font-size: 16px;
  color: #333;
  margin-bottom: 0.3rem;
  font-weight: 600;
  text-align: center;
}

.coach-call-box-1 .user-card-text span {
  font-size: 14px;
  color: #343434;
  margin-top: 0rem;
  font-weight: 400;
  text-align: center;
}

.care-navigator-consultation-box{
  background-color: #F2F2F2;
  padding: 20px;
  border-radius: 14px;
}


.coach-call-box-1 .consultation-pics-box {
  height: 196px;
  position: relative;
  width: 196px;
}

.coach-call-box-1 .consultation-pics-img {
  background-color: #fff;
  border: 1px solid #e8edf7;
  border-radius: 24px;
  display: block;
  height: 196px;
  object-fit: cover;
  width: 196px;
}

.call-disposition-title{
  color: #6A6A6A;
  margin-bottom: 0rem;
  font-weight: 500;
  text-align: left;
  font-size: 18px;
}

.call-disposition-subtitle{
  color: #6A6A6A;
  margin-bottom: 0.3rem;
  font-weight: 400;
  text-align: left;
  font-size: 14px;
}
.call-disposition-status{
  width: 100%;
}

.member-number-title {
  font-size: 32px;
  font-weight: 600;
  color: #333;
  margin-bottom: 0rem;
}
.member-number-title span{
  font-size: 22px;
}

.member-number-text {
  font-size: 18px;
  color: #333;
  margin-bottom: 0rem;
  font-weight: 400;
}

div#highcharts-0762nz4-0 {
  height: 210px !important;
}




.member-engagement .bg-grey {
  background-color: #f0f4ff;
}

.member-engagement .bg-lightgreen {
  background-color: #e6faec;
}

.member-engagement .bg-lightblue {
  background-color: #f0f4ff;
}

.member-engagement .bg-lightred {
  background-color: #ffeef0;
}

.member-engagement .card-icon {
  width: 54px;
  height: 54px;
  margin-bottom: 1rem;
}

.member-engagement .card-title1 {
  font-size: 26px; 
  font-weight: 600; 
  color: #333; 
  margin-bottom: 0.3rem; 
  text-align: left;
}

.member-engagement .card-title1 span{
  font-size: 18px; 
}

.member-engagement .card-title2 {
  font-size: 22px; 
  font-weight: 600; 
  color: #333; 
  margin-bottom: 0.3rem; 
  text-align: left;
}

.member-engagement .card-title2 span{
  font-size: 16px; 
}

.member-engagement .card-text1 {
  font-size: 14px;
  color: #636773;
  margin-bottom: 0rem;
  font-weight: 400;
  text-align: left;
}

#audioPopupModal .modal-header {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: row !important;
  flex-shrink: 0;
}
#audioPopupModal button.btn-close {
  position: relative;
  top: -30px;
}
#audioPopupModal .modal-title {
      color: #404040;
    font-size: 22px;
    font-weight: 600;
    width: 80%;
}
#audioPopupModal .modal-subtitle {
  font-size: 16px;
    font-weight:400;
    margin-bottom: 0px;
    text-align: left;
    width: 80%;
}
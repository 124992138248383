.hide {
  display: none !important;
}

.messageDiv {
  background-color: #71BC78;
  color: #000;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 10px;
  border: 1px solid #00ff00;
}

.errorDiv {
  background-color: #FB6180;
  color: #000;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 10px;
  border: 1px solid #ff0000;
}

/* width */
::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px #BABFD1 !important;
  border-radius: 10px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #BABFD1 !important;
  border-radius: 10px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #717171 !important;
}


.pad-10 {
  padding: 10px;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;

}

.assessment .table tbody tr td {
  padding: 2px 0;
  padding-right: 8px;
  text-align: left;
  border-bottom: 0px solid #ddd !important;
}

.table-borderless> :not(caption)>*>* {
  border-bottom-width: 0px !important;
}

.assessment .table {
  overflow-x: scroll;
  width: 100%;
}

#wpnb1.modal-xl {
  --bs-modal-width: 94% !important;
}


.addNewRow-btn{
  color: #02335b !important;
  background: #ffffff !important;
  border: 0 !important;
  border-bottom: 1px solid #02335b !important;
  font-size: 15px !important;
  padding: 4px 0px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 600 !important;
  border-radius: 0 !important;
}

.addNewRow-btn:hover {
  color: #1279be !important;
  background: #fff !important;
  border: 0 !important;
  border-bottom: 1px solid #1279be!important;
}

#uncontrolled-tab-example-tabpane-Assessment input[type="radio"] {
  margin: 10px;
}
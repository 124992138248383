.ContentMemberConnectAgent-container{
  display: block;
  width: 100%;
  background: #f1f5fc;
  min-height: 100vh;
}

.ContentMemberConnectAgent{
  padding: 10px 20px;
  margin: 0 auto;
  width: 100%;
}

.comntent-ContentMemberConnectAgent{
  background-color: #fff;
  color: #000;
  padding: 10px;
  border-radius: 14px;
  /* height: 87vh; */
  display: block;
  width: 100%;
  /* overflow-y: scroll;
  overflow-x: hidden; */
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  margin-bottom: 4rem !important;
}

.ContentMemberConnectAgent .main-title {
  font-size: 22px;
  color: #656565;
  font-weight: 600;
  margin-bottom: 10px;
}

.ContentMemberConnectAgent .main-subtitle {
  font-size: 22px;
  color: #656565;
  font-weight: 600;
  margin-bottom: 10px;
}

.ContentMemberConnectAgent .card-box-agent{
  border-radius: 14px;
  border: 0;
  border-radius: 14px;
  box-shadow: 0 3px 12px rgb(0 0 0 / 9%);
}

.ContentMemberConnectAgent .agent-card-title {
  font-size: 22px;
  font-weight: 700;
  color: #333;
  margin-bottom: 0rem;
}

.ContentMemberConnectAgent .agent-card-title span{
  font-size: 18px;
  font-weight: 400;
}

.ContentMemberConnectAgent .agent-card-subtitle {
  font-size: 17px;
  font-weight: 600;
  color: #333;
  margin-bottom: 0rem;
}

.ContentMemberConnectAgent .agent-card-subtitle1 {
  font-size: 16px;
  font-weight: 800;
  color: #333;
  margin-bottom: 0rem;
}

.ContentMemberConnectAgent .card-box-agent-green{
  border-radius: 14px;
  border: 0;
  background-color: #fff;
  border-radius: 14px;
  box-shadow: 0 3px 12px rgb(0 0 0 / 9%);
}

.ContentMemberConnectAgent .card-box-agent-green th{
  /* background-color: transparent !important; */
  background-color: #fff !important;
  font-size: 14px;
  vertical-align: top;
  font-weight: 500;
}

.ContentMemberConnectAgent .card-box-agent-green td{
  font-size: 13px;
  font-weight: 400;
  color:#333;
  border: 0;
}

.card-box-agent-green tbody>tr:nth-of-type(odd)>* {
  --bs-table-bg-type: #F6F6F6 !important;
}


.card-box-agent-green tbody>tr:nth-of-type(even)>* {
  --bs-table-bg-type: #E1FCFC!important;
}



.ContentMemberConnectAgent .card-box-agent-yellow{
  border-radius: 14px;
  border: 0;
  background-color: #FFF6D4;
  border-radius: 14px;
  box-shadow: 0 3px 12px rgb(0 0 0 / 9%);
}

.ContentMemberConnectAgent .card-box-agent-yellow th{
  background-color: #E1E1E1 !important;
  font-size: 14px;
  vertical-align: top;
  font-weight: 500;
}

.ContentMemberConnectAgent .card-box-agent-yellow td{
  font-size: 13px;
  font-weight: 400;
  color:#333;
  border: 0;
}

.card-box-agent-yellow tbody>tr:nth-of-type(odd)>* {
  --bs-table-bg-type: #FFF6D4!important;
}


.card-box-agent-yellow tbody>tr:nth-of-type(even)>* {
  --bs-table-bg-type: #FFF6D4!important;
}


.agent-call-box{
  border: 0;
  background: #4A4A4A 0% 0% no-repeat padding-box;
  border-radius: 24px 24px 0 0;
  padding: 20px;
}

.agent-call-box .coach-pics-box {
  height: 120px;
  position: relative;
  width: 120px;
}

.agent-call-box .coach-pics-img {
  background-color: #fff;
  border: 1px solid #e8edf7;
  border-radius: 230px;
  display: block;
  height: 120px;
  object-fit: cover;
  width: 120px;
}

.agent-call-box .coach-card-title {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0.3rem;
  text-align: left;
}

.agent-call-box .coach-card-subtitle {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 0rem;
  text-align: left;
}
.agent-call-box .coach-call-btn-box{
    display: flex;
    justify-content: space-around;
    align-items: center;
}


.agent-call-box-1 .user-pics-box {
  height: 72px;
  position: relative;
  width: 72px;
}

.agent-call-box-1 .user-pics-img {
  background-color: #fff;
  border: 1px solid #e8edf7;
  border-radius: 230px;
  display: block;
  height: 72px;
  object-fit: cover;
  width: 72px;
}

.agent-call-box-1 .user-card-title {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin-bottom: 0rem;
  text-align: left;
}
.agent-call-box-1 .user-card-subtitle {
  font-size: 16px;
  font-weight: 500;
  color: #636773;
  margin-bottom: 0rem;
  line-height: 1.7;
  text-align: left;
}
.agent-call-box-1 .user-card-text {
  font-size: 16px;
  color: #555555;
  margin-bottom: 0.3rem;
  font-weight: 600;
  text-align: left;
}

.agent-call-box-1 .user-card-text span {
  font-size: 14px;
  color: #343434;
  margin-top: 0rem;
  font-weight: 400;
  text-align: center;
}

.care-navigator-consultation-box{
  background-color: #F2F2F2;
  padding: 20px;
  border-radius: 14px;
}


.agent-call-box-1 .consultation-pics-box {
  height: 196px;
  position: relative;
  width: 196px;
}

.agent-call-box-1 .consultation-pics-img {
  background-color: #fff;
  border: 1px solid #e8edf7;
  border-radius: 24px;
  display: block;
  height: 196px;
  object-fit: cover;
  width: 196px;
}

.agent-bg-box-grey{
  background: #F5F5F5 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 14px;
}

.agent-bg-box-corner:before {
  content: '';
  position: absolute;
  left: -14px;
  top: 28px;
  border-style: solid;
  border-width: 10px 14px 10px 0;
  border-color: rgba(0, 0, 0, 0) #003E6B rgba(44, 25, 25, 0) rgba(0, 0, 0, 0);
}

#scriptModalModal .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
}

.box-script-ui{
  position: absolute;
  border: 1px solid #DCDCDC;
  border-radius: 20px 0px 20px 20px; 
  width: 47% !important;
}

.box-script-ui-subtitle {
  font-size: 15px;
  font-weight: 500;
  color: #636773;
  margin-bottom: 0rem;
  line-height: 1.3;
  text-align: left;
}

.box-script-ui-width100{
  width: 100% !important;
}
#agentCallModal .modal-header {
    display: flex;
    flex-shrink: 0;
    flex-direction: row !important;
    align-items: center;
    align-content: center;
}



.ContentMemberConnectAgent .card-box-agent-white{
  border-radius: 14px;
  border: 0;
  background-color: #fff;
  border-radius: 14px;
  box-shadow: 0 3px 12px rgb(0 0 0 / 9%);
}

.ContentMemberConnectAgent .card-box-agent-white th{
  /* background-color: transparent !important; */
  background-color: #fff !important;
  font-size: 14px;
  vertical-align: top;
  font-weight: 500;
}

.ContentMemberConnectAgent .card-box-agent-white td{
  font-size: 13px;
  font-weight: 400;
  color:#333;
  border: 0;
}

.card-box-agent-white tbody>tr:nth-of-type(odd)>* {
  --bs-table-bg-type: #F6F6F6 !important;
}


.card-box-agent-white tbody>tr:nth-of-type(even)>* {
  --bs-table-bg-type: #fff!important;
}


.time_slot{ 
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e0e0e0;
  margin: 0 2px; 
  padding: 0 3px;
  border-radius: 4px;
  cursor: pointer; 
}



.time_slot.active{ 
  background-color: #1279be ;
  color: #fff;
}


.disposition_from .module-card-title {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin-bottom: 0rem;
  text-align: left;
}

h3.user-card-subtitle.col-7 {
  color: #333;
  font-weight: 500;
}

#memberProfileDetails .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  align-content: center;
}

.agent-bg-box-grey button.accordion-button{
  background:#567891;
  color: #fff;
  font-weight: 600;
  padding:6px 10px;
  line-height: 1;
}

.agent-bg-box-grey button.accordion-button-grey{
  background:#b8c0c6;
  color: #fff;
  font-weight: 600;
  padding:6px 10px;
  line-height: 1;
}

.agent-bg-box-grey .accordion-button:not(.collapsed){
  background:#003E6B;
  color: #fff;
  font-weight: 600;
}

.agent-bg-box-grey .accordion-button-grey:not(.collapsed){
  background:#b8c0c6;
  color: #fff;
  font-weight: 600;
}

.agent-bg-box-grey .accordion-button::after{
  filter: invert(1);
}

.replicateFHN-btn {
  color: #333 !important;
  background: #C9C9C9!important;
  border: 1px solid #C9C9C9 !important;
  font-size: 12px !important;
  padding: 6px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
  width:100% !important;
}
.ContentMemberDeEnrollment{
    padding: 10px 20px;
}

.comntent-MemberDeEnrollment{
    background-color: #fff;
    color: #000;
    padding: 10px;
    border-radius: 14px;
    /* height: 87vh; */
    display: block;
    width: 100%;
    /* overflow-y: scroll;
    overflow-x: hidden; */
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    margin-bottom: 4rem !important;
}

.ContentMemberDeEnrollment .main-title {
    font-size: 22px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 10px;
}

.ContentMemberDeEnrollment .main-subtitle {
    font-size: 22px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 10px;
}

.ContentMemberDeEnrollment .DefaultLeaderBoardimage {
    height: 180px;
    position: relative;
    width: 180px;
}

.ContentMemberDeEnrollment .profile-img {
    background-color: #fff;
    border: 4px solid #e8edf7;
    border-radius: 230px;
    display: block;
    height: 180px;
    object-fit: cover;
    width: 180px;
}

.ContentMemberDeEnrollment .icon-edit-btn{
    position: absolute;
    bottom: 10px;
    right: 20px;
    border-radius: 30px;
    height: 42px;
    width: 42px;
    background-color: #9F9F9F;
    border: 0;
}

a.btn-approve{
    color: #03335b;
    font-weight: 600;
}

a.btn-approve:hover{
    color: #1279be;
    font-weight: 600;
}

a.btn-reject{
    color: #848484;
    font-weight: 600;
}

a.btn-reject:hover{
    color: #1279be;
    font-weight: 600;
}

.ContentMemberDeEnrollmentTable .gdBOih:nth-child(1), .ContentMemberDeEnrollmentTable .dBiUCQ:nth-child(1){
    min-width: 140px !important;
}

.ContentMemberDeEnrollmentTable .gdBOih:nth-child(2), .ContentMemberDeEnrollmentTable .dBiUCQ:nth-child(2){
    min-width: 140px !important;
}

.ContentMemberDeEnrollmentTable .gdBOih:nth-child(3), .ContentMemberDeEnrollmentTable .dBiUCQ:nth-child(2){
    min-width: 160px !important;
}

.ContentMemberDeEnrollmentTable .gdBOih:nth-child(4), .ContentMemberDeEnrollmentTable .dBiUCQ:nth-child(4){
    min-width: 160px !important;
}

.ContentMemberDeEnrollmentTable .gdBOih:nth-child(5), .ContentMemberDeEnrollmentTable .dBiUCQ:nth-child(5){
    min-width: 160px !important;
}

.ContentMemberDeEnrollmentTable .gdBOih:nth-child(6), .ContentMemberDeEnrollmentTable .dBiUCQ:nth-child(6){
    min-width: 160px !important;
}

.ContentMemberDeEnrollmentTable .gdBOih:nth-child(7), .ContentMemberDeEnrollmentTable .dBiUCQ:nth-child(7){
    min-width: 160px !important;
}

.ContentMemberDeEnrollmentTable .gdBOih:nth-child(8), .ContentMemberDeEnrollmentTable .dBiUCQ:nth-child(8){
    min-width: 160px !important;
}

.ContentMemberDeEnrollmentTable .gdBOih:nth-child(9), .ContentMemberDeEnrollmentTable .dBiUCQ:nth-child(9){
    min-width: 160px !important;
}

.ContentMemberDeEnrollmentTable .gdBOih:nth-child(10), .ContentMemberDeEnrollmentTable .dBiUCQ:nth-child(10){
    min-width: 160px !important;
}

.ContentMemberDeEnrollmentTable .gdBOih:nth-child(11), .ContentMemberDeEnrollmentTable .dBiUCQ:nth-child(11){
    min-width: 160px !important;
}

.ContentMemberDeEnrollmentTable .gdBOih:nth-child(12), .ContentMemberDeEnrollmentTable .dBiUCQ:nth-child(12){
    min-width: 160px !important;
}



.ContentMemberRegisterLaterTable .gdBOih:nth-child(1), .ContentMemberRegisterLaterTable .dBiUCQ:nth-child(1){
    min-width: 140px !important;
}

.ContentMemberRegisterLaterTable .gdBOih:nth-child(2), .ContentMemberRegisterLaterTable .dBiUCQ:nth-child(2){
    min-width: 140px !important;
}

.ContentMemberRegisterLaterTable .gdBOih:nth-child(3), .ContentMemberRegisterLaterTable .dBiUCQ:nth-child(3){
    min-width: 140px !important;
}

.ContentMemberRegisterLaterTable .gdBOih:nth-child(4), .ContentMemberRegisterLaterTable .dBiUCQ:nth-child(4){
    min-width: 160px !important;
}

.ContentMemberRegisterLaterTable .gdBOih:nth-child(5), .ContentMemberRegisterLaterTable .dBiUCQ:nth-child(5){
    min-width: 140px !important;
}
.ContentMemberRegisterLaterTable .gdBOih:nth-child(6), .ContentMemberRegisterLaterTable .dBiUCQ:nth-child(6){
    min-width: 160px !important;
}
.ContentMemberRegisterLaterTable .gdBOih:nth-child(7), .ContentMemberRegisterLaterTable .dBiUCQ:nth-child(7){
    min-width: 160px !important;
}
.ContentMemberRegisterLaterTable .gdBOih:nth-child(8), .ContentMemberRegisterLaterTable .dBiUCQ:nth-child(8){
    min-width: 180px !important;
}
.ContentMemberRegisterLaterTable .gdBOih:nth-child(9), .ContentMemberRegisterLaterTable .dBiUCQ:nth-child(9){
    min-width: 140px !important;
}
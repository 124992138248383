.pagination-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 5px;
    font-size: 0.9rem;
    gap: 30px;
}

.pagination-container .rows-per-page,
.pagination-container .records-info,
.pagination-container .pagination-controls {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.54);
    font-size: 13px;
    margin-top: 0px;
}

.pagination-container label {
    margin-right: 5px;
    /* Space between label and select */
    color: rgba(0, 0, 0, 0.54);
    font-size: 13px;
}

.pagination-container select {
    padding: 4px;
    color: rgba(0, 0, 0, 0.54);
    font-size: 13px;
    border: none;
}

.pagination-container .pagination-controls button {
    margin: 0 0px;
    padding: 5px 10px;
    font-size: 20px;
    border: 0px solid #ced4da;
    background-color: #fff;
    border-radius: 100px;
    cursor: pointer;
    color: #333;
}

.pagination-container .pagination-controls button[disabled] {
    color: #b8b8b8;
    cursor: not-allowed;
    background-color: #fff;
}

.pagination-container .pagination-controls button:hover:not([disabled]) {
    background-color: #e2e6ea;
    border-color: #dae0e5;
}
.LoyaltyProgram{
    padding: 10px 20px;
    margin-bottom: 3rem !important;
}

.LoyaltyProgram .main-title {
    font-size: 22px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 10px;
}

.loyalty-program{
    background-color: #fff;
    border-radius: 14px;
    box-shadow: 0 3px 12px #00000017;
    color: #000;
    display: block;
    padding: 14px;
    width: 100%;
}
.loyalty-program .sub-title {
    color: #555;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
}


.loyalty-program .table-dark {
    --bs-table-color: #333;
    --bs-table-bg: #D4E6EF;
    --bs-table-border-color: transparent;
    --bs-table-striped-bg: #2c3034;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #373b3e;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #323539;
    --bs-table-hover-color: #fff;
    color: #333;
  }
  
  .loyalty-program td a {
    text-decoration: none;
    color: #007bff;
    text-decoration: underline;
  }
  
  .loyalty-program td img {
    width: auto;
    height: 20px;
  }
  
  .loyalty-program .table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-bg-type: #FBFBFB;
    --bs-table-border-color: #E9E9E9;
    vertical-align: middle;
  }
  
  .loyalty-program .table-striped>tbody>tr:nth-of-type(even)>* {
    --bs-table-bg-type: #fff;
    --bs-table-border-color: #E9E9E9;
    vertical-align: middle;
  }
  .loyalty-program .table th {
    background-color: #D4E6EF !important;
    }
.form-check-input:checked {
    background-color: #03335b !important;
    border-color: #03335b !important;
}

.form-check-input {
    border: 1px solid #dcdcdc !important;
    height: 22px;
    margin-right: 6px;
    width: 22px;
}

.btn-Edit {
    color: #03335b !important;
    font-weight: 600!important;
    font-size: 13px !important;
    padding: 0 !important;
}

.btn-Edit:hover {
    color: #1279be!important;
    font-weight: 600!important;
}

.btn-Cancel {
    color: #FF0000!important;
    font-weight: 600!important;
    font-size: 13px !important;
    padding: 0 !important;
}

.btn-Cancel:hover {
    color: #1279be!important;
    font-weight: 600!important;
}

.sticky-top-bar {
    background-color: #f1f5fc;
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 56px;
    z-index: 2;
}
.fade.HealthManagement.modal.show {
    padding-right: 0px !important;
}

#HealthLockerDetails .modal-body .tab-content {
    width: 100%;
    text-align: left;
    border-left: 0px solid #e9e9e9 !important;
    border-bottom: 0px solid #e9e9e9 !important;
    border-right: 0px solid #e9e9e9 !important;
    padding: 0px !important;
}

#HealthLockerDetails .modal-content {
    border-radius: 20px;
    border: 0px solid #e9e9e9 !important;
    ;
}

#HealthLockerDetails .nav-link {
    color: #333 !important;
    font-weight: 500;
    border: 0;
    padding: 5px 0px;
    margin: 0px 18px;
}

#HealthLockerDetails .nav-link.active {
    color: #0469FF !important;
    font-weight: 500;
    border: 0;
    border-bottom: 2px solid #0469FF !important;
}

.ajs-dialog {
    position: relative;
    margin: 5% auto;
    min-height: 110px;
    max-width: 500px;
    padding: 20px 10px 10px 10px !important;
    outline: 0;
    background-color: #fff;
    overflow: hidden !important;
    border-radius: 24px !important;
}

.ajs-ok {
    color: #fff !important;
    background: #03335b !important;
    border: 1px solid #03335b !important;
    font-size: 16px !important;
    padding: 8px 30px !important;
    border-radius: 10px !important;
    line-height: 1.2 !important;
    font-weight: 500 !important;
}

.ajs-ok:hover {
    color: #fff !important;
    background: #1279be !important;
    border: 1px solid #1279be !important;
}


.ajs-cancel {
    color: #fff !important;
    background: grey !important;
    border: 1px solid grey !important;
    font-size: 16px !important;
    padding: 8px 30px !important;
    border-radius: 10px !important;
    line-height: 1.2 !important;
    font-weight: 500 !important;
}

.ajs-cancel:hover {
    color: #fff !important;
    background: #1279be !important;
    border: 1px solid #1279be !important;
}

.alertify .ajs-footer .ajs-buttons.ajs-primary {
    text-align: center !important;
}

.alertify .ajs-header {
    margin: -24px -20px -20px -20px !important;
    margin-bottom: 0;
    padding: 16px 24px;
    background-color: #fff;
    color: #404040;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
}

.alertify .ajs-commands button.ajs-close {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
}

.alertify .ajs-commands {
    position: absolute;
    right: 4px;
    margin: -14px 14px 0 0 !important;
    z-index: 2;
}

.alertify .ajs-commands button {
    display: none;
    width: 10px;
    height: 10px;
    margin-left: 10px;
    padding: 8px !important;
    border: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    opacity: 0.5;
}

.alertify .ajs-body .ajs-content {
    padding: 20px !important;
    text-align: center !important;
    color: #404040 !important;
    font-size: 18px !important;
    font-weight: 500 !important;
}
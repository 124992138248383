.hand {
    cursor: pointer;
}

.serviceGreen {
   --bs-table-bg-type: #BBDABB !important;
}

.serviceYellow {
   --bs-table-bg-type: #F7E88C !important;
}

.serviceRed {
   --bs-table-bg-type: #ECBABA !important;
}

.serviceGrey {
    background-color: #c0c0c0 !important;
}

.serviceBlue {
    background-color: #bae0ff !important;
}

#hbsh label {
    font-weight: bold;
}

.bi-eye {
    cursor: pointer;
}
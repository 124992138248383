
.ContentConfiguration {
    padding: 10px 20px;
}

.content-configuration {
    background-color: #fff;
    color: #000;
    padding: 14px;
    border-radius: 14px;
    /* height: 75vh; */
    display: block;
    width: 100%;
    /* overflow-y: scroll;
    overflow-x: hidden; */
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    margin-bottom: 4rem !important;
}

.Schedule-Send-btn{
    padding: 8px 20px;
    border-radius: 12px;
    font-size: 20px;
    font-weight: 500 !important;
    background-color: #6EACF2;
    border: 0;
}
.ql-toolbar.ql-snow {
    border: 0px solid #ccc !important;
    border-bottom: 1px solid #DCDCDC !important;
}
.ql-container.ql-snow {
    border: 0px solid #ccc !important;
}

.ql-container {
    font-family: inherit !important;
    font-size: 15px !important;
    height: 100%;
    margin: 0px;
    position: relative;
}

.dropdown-container {
    border: 1px solid #DCDCDC !important;
    padding: 3px 3px !important;
    border-radius: 12px !important;
}
.carePlanChangeBGBlue{
    background: #1DFFFF;
    border-radius: 20px;
    padding: 20px;
}

.carePlanChangeTitle{
    font-size: 18px;
    color: #404040;
    font-weight: 500;
    margin-bottom: 0;
}

.carePlanChangeSubTitle{
    font-size: 16px;
    color: #404040;
    font-weight: 500;
    margin-bottom: 0;
}

.carePlanChangeSubTitle1{
    font-size: 18px;
    color: #404040;
    font-weight: 500;
    margin-bottom: 0;
}

.carePlanChangeContennt{
    font-size: 16px;
    color: #404040;
    font-weight: 400;
    margin-bottom: 0;
}

.carePlanChangeGreyBox{
    background: #EBF2F2;
    border-radius: 20px;
    padding: 20px;
}

.borderPlanRight{
    border-right: 1px solid #707070 ;
}

.borderPlanRight:last-child{
    border-right: 0;
}

.save-care-btn{
    color: #fff!important;
    background: #03335b !important;
    border: 1px solid #03335b !important;
    font-size: 16px !important;
    padding: 8px 20px !important;
    border-radius: 10px !important;
    line-height: 1.2 !important;
    font-weight: 600 !important;
}
  
.save-care-btn:hover {
    color: #fff !important;
    background: #1279be !important;
    border: 1px solid #1279be!important;
}


.pathways-new-table td a {
    text-decoration: none;
  }
  
  .pathways-new-tabletd img {
    width: auto;
    height: 20px;
  }
  
  .pathways-new-table .table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-bg-type: #F1F8FF;
    --bs-table-border-color: #F1F8FF;
    border: 0;
  }
  
  .pathways-new-table .table-striped>tbody>tr:nth-of-type(even)>* {
    --bs-table-bg-type: transparent;
    --bs-table-border-color: #fff;
    border: 0;
  }
  
  .pathways-new-table .main-pathways-title {
    font-size: 15px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 0px;
    white-space: nowrap;
    line-height: 1.6;
  }
  
  .pathways-new-table td span {
      margin-right: 16px;
      padding-right: 16px;
      color: #636773;
      font-size: 14px;
      border-right: 1px solid #b1b1b1;
  }
  .pathways-new-table td span:last-child {
    border-right: 0px solid #a3a5a7;
    margin-right: 0px;
    padding-right: 0px;
  }
.appointments-report .react-tabs__tab {
    display: inline-block;
    border: 0px solid #0017E2 !important;
    padding: 0px 0px !important;
    background: transparent !important;
    color: #333 !important;
    border-bottom: 2px solid #fff !important;
    text-transform: capitalize !important;
    margin-right: calc(var(--bs-gutter-x)* .5)!important;
    margin-left: calc(var(--bs-gutter-x)* .5) !important;
}

.appointments-report .react-tabs__tab-list {
    border-bottom: 0px solid #fff !important;
    margin: 0 0 10px;
    padding: 0;
    background-color: #fff;
}
.appointments-report .react-tabs__tab:first-child {
    border-radius: 0px !important;
}
.appointments-report .react-tabs__tab:last-child {
    border-radius: 0px !important;
}

.appointments-report .react-tabs__tab--selected {
    background: transparent!important;
    border-color: #0017E2!important;
    color: #002A9E !important;
    border-radius: 0 !important;
    border-bottom: 2px solid #0017E2 !important;
    border-left: 0px solid #0017E2 !important;
    border-right: 0px solid #0017E2 !important;
}

.appointments-report .react-tabs__tab:focus {
    border-bottom: 2px solid #0017E2 !important;
}

.appointments-report .react-tabs__tab-panel {
    padding: 0px;
    border-radius: 0px !important;
    /* height: 100%; */
    height: 385px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
}

.appointments-report h2{
    font-size: 18px;
    color: #333;
    font-weight: 500;
    margin-bottom: 0.2rem;
}

.appointments-report h3{
    font-size: 15px;
    color: #333;
    font-weight: 400;
}

.appointments-report .icon-appointments-report{
    height: 48px;
    width: 48px;
}

.appointments-report .icon-statistics-report{
    height: 24px;
    width: auto;
}

.appointments-report h4{
    font-size: 14px;
    color: #333;
    font-weight: 600;
    margin-bottom: 0.2rem;
}

.appointments-report p{
    font-size: 14px;
    color: #333;
    font-weight: 400;
    margin-bottom: 0px;
}

.book-now-btn{
    color: #fff!important;
    background: #00BC32 !important;
    border: 1px solid #00BC32 !important;
    font-size: 14px !important;
    padding: 6px 10px !important;
    border-radius: 8px !important;
    line-height: 1.2 !important;
    font-weight: 600 !important;
  }
  
  .book-now-btn:hover {
    color: #fff !important;
    background: #578FFF !important;
    border: 1px solid #578FFF!important;
  }
  
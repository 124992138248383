.ContentProgramPerformance{
    padding: 10px 20px;
}

.content-ProgramPerformance{
    background-color: #fff;
    color: #000;
    padding: 10px;
    border-radius: 14px;
    /* height: 87vh; */
    display: block;
    width: 100%;
    /* overflow-y: scroll;
    overflow-x: hidden; */
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    /* margin-bottom: 4rem !important; */
}

.content-ProgramPerformance::-webkit-scrollbar{
    display: none;
}

.content-ProgramPerformance .main-title {
    font-size: 22px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 10px;
}

  .table-bg{
    background: #F1F1F2 0% 0% no-repeat padding-box;
    border-radius: 12px;
  }

.table-bg .table>:not(caption)>*>* {
    background-color: #F1F1F2 !important;
    color: #636773;
}

.table-bg thead {
    border-bottom: 1px solid #707070;
}

.table-icon{
    height: 20px;
    width: 20px;;
}


.content-ProgramPerformance .bg-lightgreen {
  background-color: #EEFFFC;
}

.content-ProgramPerformance .bg-lightblue {
  background-color: #EEFAFF;
}

.content-ProgramPerformance .bg-lightred {
  background-color: #ffeef0;
}

.content-ProgramPerformance .bg-lightyellow {
  background-color: #FFF9EE;
}

.content-ProgramPerformance .card-icon {
  width: 64px;
  height: 64px;
  margin-bottom: 1rem;
}

.content-ProgramPerformance .card{
    border-radius: 14px;
    border: 1px #B8B8B8 solid;
    overflow: hidden;
    /* height: 100px; */
}
/* .content-ProgramPerformance .card .card-body{
  display: flex;
  align-items: center;
} */

.content-ProgramPerformance .card-title {
  font-size: 16px; 
  font-weight: 600; 
  color: #333; 
  margin-bottom: 0rem; 
}

.content-ProgramPerformance .card-title span{
  font-weight: 400; 
}

.content-ProgramPerformance .card-text {
  font-size: 30px;
  color: #333;
  margin-top: 0rem;
  font-weight: 600;
  line-height: 1;
}

.content-ProgramPerformance .card-text span{
  font-size: 20px;
  font-weight: 400;
}


.performance-table .table-ProgramPerformance {
  --bs-table-color: #333;
  --bs-table-bg: #D4E6EF;
  --bs-table-border-color: transparent;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: #333;
  text-align: center;
}

.performance-table tr th{
    padding-top: 14px !important;
    padding-bottom: 14px !important;
}

.performance-table .table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-bg-type: #FBFBFB;
  --bs-table-border-color: #E9E9E9;
  text-align: center;
}

.performance-table .table-striped>tbody>tr:nth-of-type(even)>* {
  --bs-table-bg-type: #fff;
  --bs-table-border-color: #E9E9E9;
  text-align: center;
}

.sticky-top-bar{
  position: sticky !important;
  top: 56px;
  background-color: #f1f5fc;
  z-index: 2;
  /* padding-bottom: 10px; */
}

.performance-main-title {
    font-size: 20px;
    color: #636773;
    font-weight: 500;
    margin-bottom: 10px;
}

.pathway-wise-table .table-ProgramPerformance {
    --bs-table-color: #333;
    --bs-table-bg: #E8F8F7;
    --bs-table-border-color: transparent;
    --bs-table-striped-bg: #2c3034;
    --bs-table-striped-color: #fff;
    --bs-table-active-bg: #373b3e;
    --bs-table-active-color: #fff;
    --bs-table-hover-bg: #323539;
    --bs-table-hover-color: #fff;
    color: #333;
    text-align: center;
  }
  
  
  .pathway-wise-table  .table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-bg-type: #FBFBFB;
    --bs-table-border-color: #E9E9E9;
    text-align: center;
  }
  
  .pathway-wise-table  .table-striped>tbody>tr:nth-of-type(even)>* {
    --bs-table-bg-type: #fff;
    --bs-table-border-color: #E9E9E9;
    text-align: center;
  }
  
  .pathway-wise-table tr th{
    padding-top: 14px !important;
    padding-bottom: 14px !important;
}




.content-ProgramPerformance .bg-grey {
  background-color: #f0f4ff;
}

.content-ProgramPerformance .bg-lightgreen {
  background-color: #e6faec;
}

.content-ProgramPerformance .bg-lightblue {
  background-color: #f0f4ff;
}

.content-ProgramPerformance .bg-lightred {
  background-color: #ffeef0;
}

.content-ProgramPerformance .card-icon {
  width: 54px;
  height: 54px;
  margin-bottom: 1rem;
}

.content-ProgramPerformance .card-title1 {
  font-size: 26px; 
  font-weight: 600; 
  color: #333; 
  margin-bottom: 0.3rem; 
  text-align: left;
}

.content-ProgramPerformance .card-text1 {
  font-size: 14px;
  color: #636773;
  margin-bottom: 0rem;
  font-weight: 400;
  text-align: left;
}


.dashboard-graph-box .table-container {
  display: flex;
  justify-content: center;
  /* margin: 20px; */
}

.dashboard-graph-box .styled-table {
  border-collapse: collapse;
  width: 100%;
}

.dashboard-graph-box .styled-table th, .dashboard-graph-box .styled-table td {
  border: 1px solid #bababa;
  text-align: center;
  padding: 8px;
}
.dashboard-graph-box .styled-table .table-title{
    font-size: 20px;
    font-weight: 500 !important;
    color: #333;
    margin-bottom: 0.3rem;
    text-align: center;
}

.dashboard-graph-box .styled-table .table-number{
  font-size: 26px;
  font-weight: 600 !important;
  color: #333;
  margin-bottom: 0.3rem;
  text-align: center;
}
.dashboard-graph-box .styled-table span{
  font-size: 13px;
  font-weight: 500;
}

.dashboard-graph-box .styled-table th {
  background-color: rgb(212, 230, 239);
  font-weight: 500;
  font-size: 15px;
  color: #333;
}
.dashboard-graph-box .styled-table .brd-l{ 
    border-left: 2px solid #8d8d8d;
}
.dashboard-graph-box .styled-table .brd-r{ 
  border-right: 2px solid #8d8d8d;
}
.dashboard-graph-box .red-text {
  color: red;
}

.dashboard-graph-box .green-text {
  color: green;
}

.dashboard-graph-box .styled-table .bg-lightblue {
  background-color: #f0f4ff !important;
}
:root {
    --fc-small-font-size: .85em;
    --fc-page-bg-color: #fff;
    --fc-neutral-bg-color: hsla(0,0%,82%,.3);
    --fc-neutral-text-color: grey;
    --fc-border-color: #e3ecfb;
    --fc-button-text-color: #fff;
    --fc-button-bg-color: #00EEFF;
    --fc-button-border-color: #00EEFF;
     --fc-button-hover-bg-color: #0090FF;
      --fc-button-hover-border-color: #0090FF;
      --fc-button-active-bg-color: #0090FF;
      --fc-button-active-border-color: #0090FF;
      --fc-event-bg-color: #1b74bc;
      --fc-event-border-color: #1b74bc;
      --fc-event-text-color: #fff;
      --fc-event-selected-overlay-color: rgba(0,0,0,.25);
      --fc-more-link-bg-color: #d0d0d0;
      --fc-more-link-text-color: inherit;
      --fc-event-resizer-thickness: 8px;
      --fc-event-resizer-dot-total-width: 8px;
      --fc-event-resizer-dot-border-width: 1px;
      --fc-non-business-color: hsla(0,0%,84%,.3);
      --fc-bg-event-color: #8fdf82;
      --fc-bg-event-opacity: 0.3;
      --fc-highlight-color: rgba(188,232,241,.3);
      --fc-today-bg-color: #ebf7f6;
      --fc-now-indicator-color: red;
  }
  


.calendar-container {
    margin: 0 auto;
  }
  
  .fc .fc-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #3f51b5;
    color: white;
    border-radius: 5px;
  }
  

  
  .fc .fc-button:hover {
    background-color: #007bb5;
  }
  
  .fc-daygrid-event {
    cursor: pointer;
    font-size: 0.9em;
    border-radius: 5px;
    color: white;
  }
  
  .fc-daygrid-event-dot {
    display: none;
  }
  
  .fc-theme-standard .fc-list-day-cushion {
    background: #f8f9fa;
  }
  
  .fc-theme-standard td, .fc-theme-standard th {
    border: 1px solid #dee2e6;
  }
  
  .fc-theme-standard .fc-scrollgrid {
    border: none;
  }
  
  .fc-daygrid-event {
    margin: 2px 0;
  }
  
  .fc .fc-daygrid-event-dot {
    border: none;
  }


.fc .fc-button {
    border-radius: 1.25em;
    padding: 0.4em 1em 0.5em 1em;
    font-size: 16px;
    font-weight: 500;
}

.fc-theme-standard .fc-scrollgrid {
    border: 1px solid var(--fc-border-color);
    border-radius: 0 0 10px 10px;
    overflow: hidden;
}

.fc .fc-col-header-cell-cushion {
    display: inline-block;
    padding: 15px 4px;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
}

.fc-timegrid-event-harness > .fc-timegrid-event {
    inset: 0px;
    position: absolute;
    border-radius: 30px;
    width: 98%;
}

.fc-timegrid-event .fc-event-main {
    padding: 0px 0px 0px;
    /* padding: 1px 14px 0px; */
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    /* overflow: hidden; */
    /* width: 94%; */
    width: 100%;
    border-radius: 30px;
}
.fc-timegrid-event .fc-event-main-dt {
    padding: 1px 14px 0px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 30px;
    width: 100%;
    height: 100%;
    font-size: 14px;
}

.fc-event-main-dt {
    padding: 4px;
    border-radius: 4px;
    color: #fff;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event, .fc-timegrid-event.fc-event-mirror, .fc-timegrid-more-link {
    box-shadow: 0 0 0 1px rgb(255 255 255 / 70%);
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px; */
}

.fc-theme-standard td, .fc-theme-standard th {
    border: 1px solid var(--fc-border-color);
    border-right: 0px;
    /* border-bottom: 0; */
}

.fc .fc-daygrid-day-number {
    padding: 6px;
    position: relative;
    z-index: 4;
    /* background-color: #e3ecfb; */
    border-radius: 27px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4px;
    color: #858585;
}

.fc th {
    background: #e3ecfb;
}

.fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0em;
    background: #274175;
    padding: 5px;
    border-radius: 0 0;
}

.fc .fc-toolbar-title {
    font-size: 1em;
    margin: 0px;
    color: #fff;
}

.fc-v-event {
    background-color: transparent !important;
    border: 0px solid transparent !important;
    display: block;
}

.fc-view-harness.fc-view-harness-active {
    height: 1100px !important;
}

.custom-event {
    color: white;
    border-radius: 5px;
    padding: 5px;
}


a {
    color: #337ab7;
    text-decoration: none;
}

a {
    background-color: transparent;
}

a,
a:focus,
a:hover,
a:active {
    outline: 0;
    text-decoration: none;
}

a {
    text-shadow: none !important;
    color: #0d638f;
    transition: color 0.1s linear 0s, background-color 0.1s linear 0s, opacity 0.1s linear 0s !important;
}

  
  .fc .fc-toolbar {
    margin-bottom: 1em;
  }
  
  .fc .fc-event {
    cursor: pointer;
    font-size: 0.9em;
    padding: 0px 0;
    border-radius: 8px;
  }
  
  .fc .fc-event.red {
    background-color: #f44336;
    border-color: #f44336;
  }
  
  .fc .fc-event.green {
    background-color: #4caf50;
    border-color: #4caf50;
  }
  
  .fc .fc-event.yellow {
    background-color: #ffeb3b;
    border-color: #ffeb3b;
  }
  
  .fc-daygrid-event-dot {
    display: none;
  }
  
  .fc-theme-standard .fc-list-day-cushion {
    background: #f8f9fa;
  }
  
  .fc-theme-standard td, .fc-theme-standard th {
    border: 1px solid #dee2e6;
  }
  
  .fc-theme-standard .fc-scrollgrid {
    border: none;
  }
  
  .fc .fc-button-primary {
    background-color: var(--fc-button-bg-color);
    border-color: var(--fc-button-border-color);
    color: var(--fc-button-text-color);
}

.calendar-label-box{
  border-radius: 8px;
  padding: 4px 6px;
  font-size: 12px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.fc .fc-daygrid-more-link {
  cursor: pointer;
  line-height: 1.2;
  margin-top: 1px;
  max-width: 100%;
  overflow: hidden;
  padding: 3px 6px;
  position: relative;
  white-space: nowrap;
  z-index: 4;
  background-color: #e4ecfb;
  border-radius: 8px;
  font-size: 14px;
}

.fc-daygrid-day-bottom {
    display: flex;
    justify-content: center;
}
.icon-phone-schd{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.fc .fc-more-popover .fc-popover-body {
  min-width: 160px;
  padding: 1px;
}
.calendar-label-box {
  .name {
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    white-space: pre-line;
  }

  img{
    margin-left: 5px;
    height: 12px !important;
  }
}

#appointmentSchedule label{
  font-size:16px;
  font-weight:400;
}
/* 
.fc .fc-timegrid-bg-harness {
  left: 0px;
  position: absolute;
  right: 0px;
  display: none;
} */

.fc .fc-timegrid-bg-harness {
    left: 0px;
    position: absolute;
    right: 0px;
    border-radius: 8px;
    padding: 4px 6px;
    font-size: 12px;
    display: flex;
    width: 95.5%;
    align-items: center;
    justify-content: space-between;
    margin: 0px 2.5% 0px 2px;
}

.fc .fc-timegrid-bg-harness .calendar-label-box{
  background-color: #fff
}

.fc .fc-timegrid-bg-harness .calendar-label-box {
  background-color: rgb(85, 148, 125) !important;
  height: 84%;
}

.fc-event.fc-event-start.fc-event-end.fc-event-today.fc-event-future.fc-bg-event {
  background-color: transparent !important;
}

.fc-timegrid-event .fc-event-main .timeText{
  display: none;
}


.fc-timegrid-event .fc-event-main .name {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: pre-line;
  flex-direction: row-reverse;
  width: 100%;
}

.fc-timegrid-event .fc-event-main .icon-phone-schd {
  width: auto;
}
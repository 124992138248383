.chatDiv {
    font-size: 14px;
    /* border: 0px solid #e9e9e9; */
    border-radius: 16px;
    padding-left: 6px;
    padding-top: 6px;
    background-color: #fff;
    top: 126px;
    position: sticky;
    height: calc(100vh - 210px);
    display: flex;
    flex-direction: column;
}

.box {
    border: 0px solid #D5D5D5;
    padding: 10px;
    margin: 10px;
    text-align: center;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
    border: 0px;
    border-radius: 4px;
}

.red-bg {
    background-color: #ff0000;
}

.pad-10 {
    padding: 10px !important;
}

.white-text {
    color: #fff;
}

.small-text {
    font-size: 11px !important;
}

.convo-title {
    padding: 10px 0px;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
}

.chat-box-container {
    background-color: #DFF6FF;
    border-radius: 10px;
}

.chat-box-cover {
    height: 67vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
}

.chat-info {
    display: block;
    width: 100%;
}

.fa-exclamation-triangle {
    color: #FAD271;
    font-size: 40px;
}

.chat-postbox {
    padding: 10px 10px;
}

.fa-face-smile,
.fa-camera {
    color: #a4a4a4;
    font-size: 20px;
    padding: 10px;
}
.ContentCareNavigatorAppointments{
    padding: 10px 20px;
}

.comntent-ContentCareNavigatorAppointments{
    background-color: #fff;
    color: #000;
    padding: 14px;
    border-radius: 14px;
    /* height: 75vh; */
    display: block;
    width: 100%;
    /* overflow-y: scroll;
    overflow-x: hidden; */
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    margin-bottom: 4rem !important;
}

.ContentCareNavigatorAppointments .main-title {
    font-size: 22px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 10px;
}

.ContentCareNavigatorAppointments .main-subtitle {
    font-size: 22px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 10px;
}

.ContentCareNavigatorAppointments .DefaultLeaderBoardimage {
    height: 180px;
    position: relative;
    width: 180px;
}

.ContentCareNavigatorAppointments .profile-img {
    background-color: #fff;
    border: 4px solid #e8edf7;
    border-radius: 230px;
    display: block;
    height: 180px;
    object-fit: cover;
    width: 180px;
}

.ContentCareNavigatorAppointments .icon-edit-btn{
    position: absolute;
    bottom: 10px;
    right: 20px;
    border-radius: 30px;
    height: 42px;
    width: 42px;
    background-color: #9F9F9F;
    border: 0;
}

.jURlRn div:first-child{
    white-space: normal !important;
}
/* 
.CareNavigatorAppointmentsTable .gdBOih:nth-child(1), .CareNavigatorAppointmentsTable .dBiUCQ:nth-child(1){
    min-width: 280px !important;
}

.CareNavigatorAppointmentsTable .gdBOih:nth-child(2), .CareNavigatorAppointmentsTable .dBiUCQ:nth-child(2){
    min-width: 200px !important;
}

.CareNavigatorAppointmentsTable .gdBOih:nth-child(3), .CareNavigatorAppointmentsTable .dBiUCQ:nth-child(3){
    min-width: 200px !important;
}

.CareNavigatorAppointmentsTable .gdBOih:nth-child(5), .CareNavigatorAppointmentsTable .dBiUCQ:nth-child(5){
    min-width: 100px !important;
} */

.CareNavigatorAppointmentsTable .gdBOih:nth-child(6), .CareNavigatorAppointmentsTable .dBiUCQ:nth-child(6){
    min-width: 150px !important;
    max-width: 150px !important;
}

#editAppointmentModal .modal-header{
    display: flex;
    flex-shrink: 0;
    flex-direction: row !important;
    align-items: center;
    align-content: center;
}

#showListDataTable  .modal-header{
    display: flex;
    flex-shrink: 0;
    flex-direction: row !important;
    align-items: center;
    align-content: center;
}
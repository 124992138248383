.ContentUploadMemberDetails{
    padding: 10px 20px;
}

.content-UploadMemberDetails{
    background-color: #fff;
    color: #000;
    padding: 14px;
    border-radius: 14px;
    /* height: 75vh; */
    display: block;
    width: 100%;
    /* overflow-y: scroll;
    overflow-x: hidden; */
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    margin-bottom: 4rem !important;
}

.text-allowed-file{
    font-size: 16px;
    color: #7D7D7D;
    font-weight: 400;
    margin-bottom: 10px;
    line-height: 24px;
}

.text-upload-title{
    font-size: 16px;
    color: #7D7D7D;
    font-weight: 600;
    margin-bottom: 10px;
    line-height: 24px;
}

.text-upload-titlesub{
    font-size: 16px;
    color: #7D7D7D;
    font-weight: 400;
    margin-bottom: 10px;
    line-height: 24px;
}

.text-upload-main-title{
    font-size: 20px;
    color: #636773;
    font-weight: 600;
    margin-bottom: 10px;
    line-height: 24px;
    margin-top: 20px;
}

.gdBOih, .dBiUCQ, .bMhZUR {
    min-width: 130px !important;
}
.eFEHrh div:first-child {
    white-space: normal !important;
}

.content-UploadMemberDetails .react-tabs__tab:first-child {
    border-radius: 14px 0 0 0px !important;
}
    
.content-UploadMemberDetails .react-tabs__tab:last-child {
    border-radius: 0 14px 0px 0 !important;
}

.content-UploadMemberDetails .react-tabs__tab {
    display: inline-block;
    border-bottom: none;
    bottom: 0px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    color: #A4A4A4 !important;
}

.content-UploadMemberDetails .react-tabs__tab-panel {
    padding: 20px;
    border-radius: 0px 14px 14px 14px !important;
    background-color: #fff;
}

.content-UploadMemberDetails .react-tabs__tab-list {
    border-bottom: 1px solid #aaa !important;
}

.content-UploadMemberDetails .react-tabs__tab {
    background: #fff !important;
    border-color: #fff !important;
    border-radius: 0 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    padding: 10px 34px !important;
    margin: 0 2px 0px 0 !important;
    text-transform: uppercase !important;
    cursor: pointer;
    transition: all 0.3s ease;
    height: 48px;
}

.content-UploadMemberDetails .react-tabs__tab--selected {
    background: #fff !important;
    border-color: #959595 !important;
    color: #636773 !important;
    border-radius: 0 !important;
    border: 0 !important;
    border-bottom: 3px solid #959595 !important;
    font-weight: 600 !important;
}

.content-UploadMemberDetails .react-tabs__tab:focus {
    outline: none;
    border-bottom: 3px solid #959595 !important;
}

.content-UploadMemberDetails .react-tabs__tab:focus:after {
    background: transparent !important;
  }

#bulkDataMigrationModal .modal-title{
    font-size: 30px;
    color: #7D7D7D;
    font-weight: 600;
    margin-bottom: 10px;
    line-height: 24px;
  }

  #bulkDataMigrationModal .text-sub-title {
    font-size: 18px;
    color: #7D7D7D;
    font-weight: 600;
    margin-bottom: 10px;
    line-height: 24px;
    margin-top: .5rem;
}

#bulkDataMigrationModal .text-content {
    font-size: 15px;
    color: #7D7D7D;
    font-weight: 400;
    margin-bottom: 10px;
    line-height: 24px;
    margin-left: 0.5rem;
}

#bulkDataMigrationModal .bg-content {
   background-color: #fff;
   border-radius: 10px;
   width: 100%;
}

#bulkDataMigrationModal .modal-content {
    border-radius: 24px !important;
    background-color: #F1F1F1;
}

.tableDataMigrate .gdBOih:nth-child(1), .tableDataMigrate .dBiUCQ:nth-child(1){
    min-width: 150px !important;
}

.tableDataMigrate .gdBOih:nth-child(2), .tableDataMigrate .dBiUCQ:nth-child(2){
    min-width: 230px !important;
}

.tableDataMigrate .gdBOih:nth-child(12), .tableDataMigrate .dBiUCQ:nth-child(12){
    min-width: 230px !important;
}

.tableDataMigrate .gdBOih:nth-child(25), .tableDataMigrate .dBiUCQ:nth-child(25){
    min-width: 230px !important;
}

.tableDataMigrate .gdBOih:nth-child(26), .tableDataMigrate .dBiUCQ:nth-child(26){
    min-width: 230px !important;
}

.tableDataMigrate .gdBOih:nth-child(27), .tableDataMigrate .dBiUCQ:nth-child(27){
    min-width: 230px !important;
}

.tableDataMigrate .gdBOih:nth-child(28), .tableDataMigrate .dBiUCQ:nth-child(28){
    min-width: 230px !important;
}

.tableDataMigrate .gdBOih:nth-child(29), .tableDataMigrate .dBiUCQ:nth-child(29){
    min-width: 230px !important;
}

.tableDataMigrate .gdBOih:nth-child(30), .tableDataMigrate .dBiUCQ:nth-child(30){
    min-width: 230px !important;
}

.tableDataMigrate .gdBOih:nth-child(31), .tableDataMigrate .dBiUCQ:nth-child(31){
    min-width: 230px !important;
}

.tableDataMigrate .gdBOih:nth-child(32), .tableDataMigrate .dBiUCQ:nth-child(32){
    min-width: 230px !important;
}

.tableDataMigrate .gdBOih:nth-child(33), .tableDataMigrate .dBiUCQ:nth-child(33){
    min-width: 230px !important;
}
.variable-mapping-row .form-label {
    font-weight: bold;
    font-size: 14px;
    display: flex;
    justify-content: center;
  }
  
  .variable-mapping-row .input-group {
    width: 100%;
  }
  
  .variable-mapping-row .form-control {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
  }
  
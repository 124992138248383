
.CorporateChallenges{
    padding: 10px 20px;
    margin-bottom: 3rem !important;
}

.CorporateChallenges .main-title {
    font-size: 22px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 10px;
}

.corporate-challenges{
    background-color: #fff;
    border-radius: 14px;
    box-shadow: 0 3px 12px #00000017;
    color: #000;
    display: block;
    padding: 14px;
    width: 100%;
}
.corporate-challenges .sub-title {
    color: #555;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
}



.eFEHrh div:first-child{
    white-space: normal !important;
}

.tableCorporateChallenges .gdBOih:nth-child(1), .tableCorporateChallenges .dBiUCQ:nth-child(1){
    min-width: 300px !important;
}

.tableCorporateChallenges .gdBOih:nth-child(2), .tableCorporateChallenges .dBiUCQ:nth-child(2){
    min-width: 200px !important;
}

.tableCorporateChallenges .gdBOih:nth-child(3), .tableCorporateChallenges .dBiUCQ:nth-child(3){
    min-width: 300px !important;
}

.tableCorporateChallenges .gdBOih:nth-child(4), .tableCorporateChallenges .dBiUCQ:nth-child(4){
    min-width: 100px !important;
}

.tableCorporateChallenges .gdBOih:nth-child(5), .tableCorporateChallenges .dBiUCQ:nth-child(5){
    min-width: 100px !important;
}

.tableCorporateChallenges .gdBOih:nth-child(8), .tableCorporateChallenges .dBiUCQ:nth-child(6){
    min-width: 60px !important;
}